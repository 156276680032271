@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
body {
  margin: 0;
	height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
	height: 100%;
}

.App{
	height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.menu {
	position: absolute;
	background-color: #fff;
	color: rgba(0, 0, 0, 0.87);
	border-radius: 4px;
	box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
	width: 265px;
	padding:0;
	margin:0;
}
.menu a {
	text-decoration: none;
	color: inherit;
}
.menuItem {
	cursor: pointer;
	font-family:'Roboto', sans-serif;
	line-height: 1.5;
	letter-spacing: 0.00938em;
	display:-webkit-inline-flex;
	display:inline-flex;
	padding: 8px 16px;
	-webkit-align-items: center;
	        align-items: center;
}
.menuItemSelected {
	background: #EFF1F4;
}
.menuItem:hover {
	background-color: rgba(0, 0, 0, 0.04)
}

.menuItemIcon {
	min-width: 56px;
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
}

.primaryText {
	font-weight: 700;
	font-size: 1rem;
  text-align: left;
	margin-bottom: 0;
}

.secondaryText {
	font-size: 0.875rem;
	color: #6C757D;
	text-align: left;
	margin-bottom: 0;
}

svg {
	cursor: pointer
}

ul {
	list-style: none;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding: 0;
	margin: 0;
}

