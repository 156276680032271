@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.menu {
	position: absolute;
	background-color: #fff;
	color: rgba(0, 0, 0, 0.87);
	border-radius: 4px;
	box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
	width: 265px;
	padding:0;
	margin:0;
}
.menu a {
	text-decoration: none;
	color: inherit;
}
.menuItem {
	cursor: pointer;
	font-family:'Roboto', sans-serif;
	line-height: 1.5;
	letter-spacing: 0.00938em;
	display:inline-flex;
	padding: 8px 16px;
	align-items: center;
}
.menuItemSelected {
	background: #EFF1F4;
}
.menuItem:hover {
	background-color: rgba(0, 0, 0, 0.04)
}

.menuItemIcon {
	min-width: 56px;
	display: inline-flex;
	flex-shrink: 0;
}

.primaryText {
	font-weight: 700;
	font-size: 1rem;
  text-align: left;
	margin-bottom: 0;
}

.secondaryText {
	font-size: 0.875rem;
	color: #6C757D;
	text-align: left;
	margin-bottom: 0;
}

svg {
	cursor: pointer
}

ul {
	list-style: none;
	display: flex;
	flex-direction: column;
	padding: 0;
	margin: 0;
}
